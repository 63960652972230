import React, { useState } from "react";
import "./styleSheets/imageGrid.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ImageCarousel = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const body = document.querySelector("body");

  const openModal = (index) => {
    body.classList.add("no-scroll");
    setCurrent(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    body.classList.remove("no-scroll");
  };

  const navigateSlides = (direction) => {
    if (direction === "prev") {
      setCurrent((current) =>
        current === 0 ? slides.length - 1 : current - 1
      );
    } else {
      setCurrent((current) =>
        current === slides.length - 1 ? 0 : current + 1
      );
    }
  };

  const handleImageClick = (e) => {
    e.stopPropagation();
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <div className="grid">
        {slides.map((slide, index) => (
          <LazyLoadImage
            key={index}
            src={slide}
            alt={`Slide ${index}`}
            effect="blur"
            onClick={() => openModal(index)}
            className="grid-item"
            width="auto"
            loading="lazy"
          />
        ))}
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="slider" onClick={handleImageClick}>
            <img
              src={slides[current]}
              alt={`Slide ${current}`}
              className="image"
              loading="lazy"
            />
            <div className="navigation-buttons">
              <div
                className="hover-area hover-left"
                onClick={(e) => {
                  e.stopPropagation();
                  navigateSlides("prev");
                }}
              >
                <FaChevronLeft />
              </div>
              <div
                className="hover-area hover-right"
                onClick={(e) => {
                  e.stopPropagation();
                  navigateSlides("next");
                }}
              >
                <FaChevronRight />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCarousel;
