import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/landingPage"; // Ensure the import path matches the file location
import MehndiPage from "./components/mehndiPage";
import MakeupPage from "./components/makeupPage";
import ContactPage from "./components/contactPage";
import Footer from "./components/footer";
import NavBar from "./components/navigationBar";
import { Analytics } from "@vercel/analytics/react";

import "./App.css";

function App() {
  return (
    <Router>
      <Analytics />
      <NavBar />
      <div className="content-wrapper">
        <main className="main-content" style={{ paddingTop: "2rem" }}>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/mehndi" element={<MehndiPage />} />
            <Route path="/hair&makeup" element={<MakeupPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
