import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner"; // Import the ThreeDots loader
import "./styleSheets/contactPage.css";
import instagram from "../images/instagram.svg";
import email from "../images/email.svg";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    service: "Mehndi",
    info: "",
    date: "",
    time: "",
  });
  const body = document.querySelector("body");

  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    body.classList.add("no-scroll");
    setIsLoading(true); // Set loading state to true

    const response = await fetch(
      "https://formsubmit.co/simmi.mehndi@gmail.com",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (response.ok) {
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        number: "",
        email: "",
        service: "Mehndi",
        info: "",
        date: "",
        time: "",
      });
    } else {
      alert("Failed to submit form.");
    }
    setIsLoading(false); // Set loading state to false
    body.classList.remove("no-scroll");
  };

  return (
    <div className="contactPage">
      <div className="info-section">
        <div className="AboutSimmiContact">
          <h1>Contact Me!</h1>
          <br />
          <div className="click-me-links">
            <a
              className="contact-instagram"
              href="https://ig.me/m/simmi.mehndi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Click Me!</h3>
              <img src={instagram} alt="Instagram" />
            </a>
            <a
              className="contact-email"
              href="mailto:Simmi.mehndi@gmail.com?subject=Hi, I'd like to get some more information. - (Replace with your Name)"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>Email Me!</h3>
              <img src={email} alt="Email" />
            </a>
          </div>
          <h1>Or</h1>
          <div className="contact-form-container">
            <form
              onSubmit={handleSubmit}
              className={isLoading ? "loading" : ""}
            >
              <h1>Send me a few details here!</h1>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                disabled={isLoading}
              />

              <label htmlFor="number">Number</label>
              <input
                type="tel"
                id="number"
                name="number"
                value={formData.number}
                onChange={handleChange}
                required
                disabled={isLoading}
              />

              <label htmlFor="email">E-Mail</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={isLoading}
              />

              <label htmlFor="service">Service Type</label>
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleChange}
                disabled={isLoading}
              >
                <option value="Mehndi">Mehndi Booking</option>
                <option value="Makeup">Makeup Booking</option>
                <option value="Bridal">Bridal Bundle Booking</option>
              </select>

              <label htmlFor="info">Extra Information</label>
              <textarea
                id="info"
                name="info"
                placeholder="Any additional information..."
                value={formData.info}
                onChange={handleChange}
                disabled={isLoading}
              ></textarea>

              <label htmlFor="date">Date of Event (optional)</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                disabled={isLoading}
              />

              <label htmlFor="time">Time of Event (optional)</label>
              <input
                type="time"
                id="time"
                name="time"
                value={formData.time}
                onChange={handleChange}
                disabled={isLoading}
              />

              <button type="submit" disabled={isLoading}>
                Submit
              </button>
            </form>
            {isLoading && (
              <div className="loader-overlay">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#00BFFF"
                  ariaLabel="three-dots-loading"
                  visible={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
