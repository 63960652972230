import React from "react";
import "./styleSheets/landingPage.css";
import simmiProfile from "../images/simmiProfile.jpg";
import itvLogo from "../icons/itv.png";
import asosLogo from "../icons/asos.png";
// import ImageReel from "./imageReel";

function MainPage() {
  return (
    <div className="homepage">
      <div className="info-section">
        <div className="landingBox">
          <h1>Hi I'm Simmi!</h1>
          <div className="landingOreintation">
            <img
              src={simmiProfile}
              alt="simmiProfile"
              className="simmi-profile-image"
            />
            <div className="simmiParagraph">
              <h2>How it all began!</h2>
              <p>
                Back in 2021, during the COVID-19 pandemic, 'Simmi Mehndi'
                started as more than just a project; it was a dream taking shape
                while I was still juggling textbooks and exams at university. It
                was about turning a heartfelt hobby into something bigger — a
                thriving, bustling business. Fast-forward to the present, and
                it's been a long but exciting adventure filled with joy, beauty,
                and the privilege of being part of so many special days, from
                intimate bridal parties to grand festive events.
                <br />
                <br />
                My little venture initially sprouted as a spot for bridal and
                guest mehndi, but since then it has grown into a place where
                beauty in all its forms is celebrated. Intricate mehndi
                patterns, expertly applied makeup, and hair styling.
                <br />
                <br />
                Every step of this journey has been steeped in gratitude — for
                the trust placed in my hands, for the moments shared, and for
                the growth that every single client has contributed to. It's a
                story of love, artistry, and unforgettable celebrations that
                I've been so fortunate to be part of. Here's to continuing this
                beautiful journey together, creating more memories for years to
                come!
              </p>
              {/* <div className="image-reel-container">
                <ImageReel />
              </div> */}
            </div>
          </div>
        </div>
        <br />
        <br />
        <h2>Some places you might've seen me</h2>
        <p>
          I've had the amazing opportunity to work with many great brands, some
          of whom are
        </p>
        <div className="brand-icons">
          <img src={itvLogo} alt="ITV" className="brand-icon" />
          <img src={asosLogo} alt="ASOS" className="brand-icon" />
        </div>
      </div>
    </div>
  );
}

export default MainPage;
