import "./styleSheets/mehndiPage.css";
import ImageCarousel from "./imageGrid";

function MehndiPage() {
  function importAll(r) {
    return r.keys().map(r);
  }

  const images = importAll(
    require.context("../smImages", false, /\.(png|jpe?g|svg)$/)
  );

  const slides = images; // This will now contain all imported images

  return (
    <div className="mehndiPage">
      <div className="info-section">
        <div className="aboutSimmiMehndi">
          <h1>Mehndi</h1>
          <p style={{ fontSize: "large", padding: "0px 50px" }}>
            Indulge in the beauty of 100% natural, freshly prepared mehndi, free
            from harsh chemicals. Whether it's for your special day or a festive
            celebration, I specialize in both Bridal and guest mehndi art,
            tailored to perfection for any occasion.
            <br />
            <br />
            Bridal mehndi is a cherished journey, meticulously crafted and
            utterly rewarding. Elevate your bridal experience with personalized
            touches – imagine adorable mementos intricately woven into your
            design, from charming portraits of the happy couple to iconic
            landmarks symbolizing your unique love story.
            <br />
            <br />
            Let me transform your mehndi experience into a cherished memory,
            where every stroke tells a tale of love and celebration.
          </p>
          <br />
          <h2>Client/Model Work</h2>
          <ImageCarousel slides={slides} />
        </div>
      </div>
    </div>
  );
}

export default MehndiPage;
