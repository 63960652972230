import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./styleSheets/navigationBar.css"; // Ensure this CSS file exists
import logo from "../images/logo.png";
import instagram from "../images/instagram.svg";
import facebook from "../images/facebook.svg";
import Navigation from "./navigationLinks";
import backgroundIMG from "../images/mehndiBackground.png";

const LogoNavBar = () => {
  const { pathname } = useLocation();
  const isHomePage = pathname === "/";
  const [isShrunk, setIsShrunk] = useState(!isHomePage);

  useEffect(() => {
    const body = document.querySelector("body");
    const mediaQuery = window.matchMedia("(min-width: 768px)");

    const handleScroll = () => {
      const shouldBeActive = window.scrollY > 0;
      if (shouldBeActive !== isShrunk) {
        setIsShrunk(shouldBeActive);
        if (shouldBeActive) {
          body.classList.add("no-scroll");
          setTimeout(() => body.classList.remove("no-scroll"), 1500);
        }
      }
    };

    if (isHomePage && mediaQuery.matches && !isShrunk) {
      const initialShouldBeActive = window.scrollY > 0;
      setIsShrunk(initialShouldBeActive);
      if (initialShouldBeActive) {
        body.classList.add("no-scroll");
        setTimeout(() => body.classList.remove("no-scroll"), 1500);
      }
      window.addEventListener("wheel", handleScroll);

      return () => {
        window.removeEventListener("wheel", handleScroll);
      };
    } else {
      setIsShrunk(true);
    }
  }, [isHomePage, isShrunk]);

  const handleExpand = (e) => {
    const body = document.querySelector("body");
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    if (
      isHomePage &&
      !e.target.closest("a") &&
      !e.target.closest("nav") &&
      !mediaQuery.matches
    ) {
      setIsShrunk(false);
      body.classList.add("no-scroll");
      setTimeout(() => body.classList.remove("no-scroll"), 1500);
    }
  };

  return (
    <div
      className={`logo-nav-bar-container ${isShrunk ? "shrinkContainer" : ""}`}
      onClick={isHomePage ? handleExpand : null}
    >
      <div className="social-media-links">
        <a
          href="https://www.instagram.com/simmi.mehndi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="Instagram" />
        </a>
        <a
          href="https://www.facebook.com/simmiravi16/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} alt="Facebook" />
        </a>
      </div>
      <img
        src={backgroundIMG}
        alt="mehndiImage"
        className="background-image-home"
      />
      <img
        src={logo}
        alt="simmiLogo"
        className={`centered-logo-home ${isShrunk ? "shrink-logo" : ""}`}
      />
      <Navigation />
    </div>
  );
};

export default LogoNavBar;
