import React from "react";
import "./styleSheets/footer.css"; // Path to your Footer CSS file

const Footer = () => {
  return (
    <footer className="footer">
      {/* Footer content goes here */}
      <p>
        © 2024 Simmi Mehndi. All rights reserved. - Designed and Created by
        @Timeless.Solutions
      </p>
    </footer>
  );
};

export default Footer;
