import "./styleSheets/makeupPage.css";

import ImageCarousel from "./imageGrid";

function MakeupPage() {
  function importAll(r) {
    return r.keys().map(r);
  }

  const images = importAll(
    require.context("../shmImages/", false, /\.(png|jpe?g|svg)$/)
  );

  const slides = images; // This will now contain all imported images

  return (
    <div className="makeupPage">
      <div className="info-section">
        <div className="aboutSimmiMakeup">
          <h1>Hair & Beauty</h1>
          <p style={{ fontSize: "large", padding: "0 50px" }}>
            Step into a world of exquisite beauty with our comprehensive bridal,
            party makeup, and guest hairstyling services, tailored to perfection
            for your special occasions. From timeless bridal looks to glamorous
            party styles, our expert team crafts flawless makeup and hairstyles
            to enhance your natural beauty.
            <br />
            <br />
            Indulge in premium products and personalized touches that elevate
            your experience. Let us transform your moments into cherished
            memories, where every brushstroke and strand tells a tale of beauty
            and celebration...
          </p>
          <br />
          <h2>Client/Model Work</h2>
          <ImageCarousel slides={slides} />
        </div>
      </div>
    </div>
  );
}

export default MakeupPage;
