import "./styleSheets/navigationLinks.css";
import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";

const Navigation = () => {
  const links = [
    { path: "/", name: "Home" },
    { path: "/mehndi", name: "Mehndi" },
    { path: "/hair&makeup", name: "Hair & Beauty" },
    { path: "/contact", name: "Contact" },
  ];

  const location = useLocation(); // Get the current location
  const navRefs = useRef(links.map(() => React.createRef()));

  return (
    <div className="navigation" id="navigation">
      <ul>
        {links.map((link, index) => (
          <li
            key={link.path}
            ref={navRefs.current[index]}
            className={location.pathname === link.path ? "active" : ""}
          >
            <Link to={link.path}>{link.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;
